import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '../dialogs/alert/alert.component';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router,
              private dialog: MatDialog) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.checkToken().pipe(
      map(res => {
        if (res.success) {
          return true;
        } else {
          this.authService.logout();
          this.router.navigate(['auth/login']);
          return false;
        }
      }),
      catchError(err => {
        this.authService.logout();
        this.router.navigate(['auth/login']);
        return of(false);
      })
    );
  }
}
