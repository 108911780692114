import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  private customRedirect: boolean;
  public successMessage = undefined;
  public messageType = undefined;
  public isVisibleNavigateToWalletButton = true;
  public isLightTheme = false;
  destroySubject$: Subject<void> = new Subject();

  constructor(public dialogRef: MatDialogRef<AlertComponent>,
              public dataService: DataService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private router: Router) {
    // {'messageType': messageType, 'messageText': messageText}
    // console.log(this.data);
    this.resetMessages();
    if (this.data.messageType && this.data.messageType > 0) {
      this.messageType = this.data.messageType;
      switch (this.messageType) {
        case 1:
        case 3:
        case 4:
          this.isVisibleNavigateToWalletButton = true;
          break;
        case 5:
        case 2: // ERROR_NO_BONUS_WALLET
          this.isVisibleNavigateToWalletButton = false;
          break;
        case 7: // data is loading
          this.isVisibleNavigateToWalletButton = false;
          break;
        case 8: // error message
          this.isVisibleNavigateToWalletButton = false;
          this.successMessage = this.data.messageText;
          break;
        // default:
        //   this.successMessage = err.error.message || err.message;
        //   break;
      }
    } else {

    }

    this.dialogRef.afterClosed()
      .subscribe(() => {
        this.redirectClose();
      });

    // if (window.localStorage.getItem('isLightTheme') === 'true') {
    //   this.isLightTheme = true;
    // } else {
    //   this.isLightTheme = this.dataService.getIsLightTheme();
    // }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }

  public redirectClose() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
    this.customRedirect = true;
    this.dialogRef.close();
    this.resetMessages();
  }

  public navigateToWallet() {
    this.router.navigate(['user-settings/wallets']).then();
    this.redirectClose();
  }

  private resetMessages() {
    this.successMessage = undefined;
    this.messageType = undefined;
  }

}
