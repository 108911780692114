import { Component, OnInit } from '@angular/core';
import {CurrencyInterface} from '../../models/currency.model';
import {Subject} from 'rxjs/internal/Subject';
import {MatDialogRef} from '@angular/material/dialog';
import {SvgService} from '../../services/svg.service';
import {DataService} from '../../services/data.service';
import {takeUntil} from 'rxjs/operators';
import {FiatClass} from '../../models/fiat.class';
import {ValidationService} from '../../services/validation.service';
import {PoliResponseInterface} from '../../models/poli-response.model';
import {ProfileState} from '../../store/profile.state';
import {fromMobx} from '../../store/rx-from-mobx';

@Component({
  selector: 'app-add-fiat',
  templateUrl: './add-fiat.component.html',
  styleUrls: ['./add-fiat.component.scss']
})
export class AddFiatComponent implements OnInit {
  public selectedCurrency: string;
  public amount: string;
  public isPending = false;
  public isUserCompletedProfileForm = false;
  public currenciesList: Array<CurrencyInterface>; // new
  public messages = {
    requestSent: false,
    walletExists: false
  };
  public isLightTheme = false;
  public errorMessage = '';
  destroySubject$: Subject<void> = new Subject();

  constructor(private dialogRef: MatDialogRef<AddFiatComponent>,
              public svgService: SvgService,
              private profileState: ProfileState,
              private dataService: DataService,
              public validationService: ValidationService) {
    this.loadIsUserCompletedProfileFormData();
    // if (window.localStorage.getItem('isLightTheme') === 'true') {
    //   this.isLightTheme = true;
    // } else {
    //   this.isLightTheme = this.dataService.getIsLightTheme();
    // }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }

  ngOnInit() {
    this.getCurrenciesList();
  }
  public getCurrenciesList() {
    // this.currenciesList = this.dataService.getSharedCurrenciesList();
    // this.dataService.getSharedCurrenciesListEmitter()
    //   .subscribe((response: Array<CurrencyInterface>) => {
    //     this.currenciesList = response;
    //   });
    this.currenciesList = [
      {name: 'AUD', fullName: 'AUD', status: null }
      ];
  }

  public close() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
    this.dialogRef.close();
  }
  private clearMessages() {
    Object.keys(this.messages).forEach(v => this.messages[v] = false);
  }
  public addFiat(currency) {
    if (this.isUserCompletedProfileForm) {
      this.errorMessage = '';
      this.isPending = true;
      this.clearMessages();
      const poliData = new FiatClass(currency, +this.amount);
      this.dataService.initiatePoli(poliData)
        .subscribe((res: PoliResponseInterface) => {
          console.log(res);
          if (res.Success && res.NavigateURL) {
            window.open(res.NavigateURL);
            this.close();
          } else if (res.ErrorMessage) {
            this.errorMessage = res.ErrorMessage;
          }
          this.isPending = false;
        }, err => {
          console.log(err);
          console.log(err.error.errorCode);
          this.errorMessage = err.error.message || err.message;
          this.isPending = false;
        }
      );
    }
  }

  validateDuringInput(field: string) {
    let value: string;
    switch (field) {
      case 'amount':
        value = this.validationService.getNumberWithDecimalsFromString(this.amount);
        if (+value === 0) {
          this.amount = '';
          (<any>window.document.getElementById('amountFiat')).value = '';
        } else {
          this.amount = value;
          (<any>window.document.getElementById('amountFiat')).value = value;
        }        break;
    }
  }

  validateDecimalNumberOnFocusOut(field: string) {
    let value: string;
    switch (field) {
      case 'amount':
        value = this.validationService.getNumberWithoutLastCommaOrDotFromString(this.amount);
        if (+value === 0) {
          this.amount = '';
          (<any>window.document.getElementById('amountFiat')).value = '';
        } else {
          this.amount = value;
          (<any>window.document.getElementById('amountFiat')).value = value;
        }
        break;
    }
  }

  public loadIsUserCompletedProfileFormData() {
    fromMobx(() => this.profileState.isUserCompletedProfileForm)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isUserCompletedProfileForm = data;
      });
  }

}
