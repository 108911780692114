import {Component, Input, OnDestroy, Output, EventEmitter} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {DataService} from '../../services/data.service';
import {takeUntil} from 'rxjs/operators';
import {SvgService} from '../../services/svg.service';
import {AuthService} from '../../services/auth.service';
import {ProfileState} from '../../store/profile.state';

@Component({
  selector: 'app-cookie-bar',
  templateUrl: './cookie-bar.component.html',
  styleUrls: ['./cookie-bar.component.scss']
})
export class CookieBarComponent implements OnDestroy {
  public isLightTheme = false;
  destroySubject$: Subject<void> = new Subject();

  @Input() isClosed: boolean;
  @Output() isClosedChange = new EventEmitter<boolean>();

  constructor(public dataService: DataService,
              public authService: AuthService,
              public profileState: ProfileState,
              public svgService: SvgService) {

    // if (window.localStorage.getItem('isLightTheme') === 'true') {
    //   this.isLightTheme = true;
    // } else {
    //   this.isLightTheme = this.dataService.getIsLightTheme();
    // }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public close(isAgreed: number) {
    this.isClosed = true;
    this.isClosedChange.emit(this.isClosed);
    if (this.authService.isLoggedIn) {
      if (this.profileState.storagedUserId) {
        this.dataService.setCookiesAgreementStatus(this.profileState.storagedUserId, isAgreed)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe(() => {
            // console.log(res);
          }, error => {
            console.log(error);
            this.isClosed = false;
            this.isClosedChange.emit(this.isClosed);

          });
      }
    } else {
      localStorage.setItem('isCookiesAgree', '' + isAgreed);
    }
  }

}
