import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientJsonpModule, HttpClient } from '@angular/common/http';

import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './partials/header/header.component';
import { FooterComponent } from './partials/footer/footer.component';
import { LandingComponent } from './pages/landing/landing.component';
// import {CryptoCredentialComponent} from './pages/cypto-credential/crypto-credential.component';
import {CryptoCredentialSendingComponent} from './pages/crypto-credential-sending/crypto-credential-sending.component';
import { TwoFactorAuthModalComponent } from './dialogs/two-factor-auth/two-factor-auth-modal.component';
import { SearchComponent } from './pages/search/search.component';
import { MarketsComponent } from './pages/markets/markets.component';
import { WithdrawalComponent } from './dialogs/withdrawal/withdrawal.component';
import { WithdrawalSuccessComponent } from './dialogs/withdrawal-success/withdrawal-success.component';
import { SignUpComponent } from './dialogs/sign-up/sign-up.component';
// import { LogInComponent } from './dialogs/log-in/log-in.component';
import {CryptoCredentialSignUpComponent} from './dialogs/crypto-credential-sign-up/crypto-credential-sign-up';
import {CryptoCredentialPaymentStatusComponent} from './dialogs/crypto-credential-payment-status/crypto-credential-payment-status';
import { PaymentStatusComponent} from './dialogs/payment-status/payment-status.component';
import { ForgotPasswordComponent } from './dialogs/forgot-password/forgot-password.component';
import { NewPasswordComponent } from './dialogs/new-password/new-password.component';
import { DataService } from './services/data.service';
import { WalletWithdrawDepositPipe } from './pipes/wallet-withdraw-deposit/wallet-withdraw-deposit.pipe';
import { WalletOrderTypePipe } from './pipes/wallet-order-type/wallet-order-type.pipe';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { HomeSearchPipe } from './pipes/home/home-search.pipe';
import { HomePlusPipe } from './pipes/home/home-plus.pipe';
import { MarketListComponent } from './pages/search/market-list/market-list.component';
import { MarketsSortPipe } from './pipes/home/markets-sort.pipe';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthPageComponent } from './pages/auth-page/auth-page.component';
import { RequestInterceptor } from './services/http_interceptor';
import { SvgService } from './services/svg.service';
import { InfoComponent } from './dialogs/info/info.component';
import { BalancesComponent } from './pages/markets/balances/balances.component';
import { OrdersComponent } from './pages/markets/orders/orders.component';
import { CalculatorComponent } from './pages/markets/calculator/calculator.component';
import { ChartComponent } from './pages/markets/chart/chart.component';
import { TickersComponent } from './pages/markets/tickers/tickers.component';
import { OrderHistoryComponent } from './pages/markets/order-history/order-history.component';
import { OrderBookComponent } from './pages/markets/order-book/order-book.component';
import { TradesComponent } from './pages/markets/trades/trades.component';
import { ChartMarketDepthComponent } from './pages/markets/chart-market-depth/chart-market-depth.component';
import { MarketDepthComponent } from './pages/markets/market-depth/market-depth.component';
import { ChartContainerComponent } from './pages/markets/chart-container/chart-container.component';
import { FilterKeysPipe } from './pipes/markets/filter-keys.pipe';
import { SearchFilterPipe } from './pipes/markets/search-filter.pipe';
import { TokenNameSortPipe } from './pipes/markets/token-name-sort.pipe';
import { LastSortPipe } from './pipes/markets/last-sort.pipe';
import { VolumeSortPipe } from './pipes/markets/volume-sort.pipe';
import { Sort24hPipe } from './pipes/markets/sort24h.pipe';
import { OrderHistoryFilterPipe } from './pipes/markets/order-history-filter.pipe';
import { PairSortPipe } from './pipes/markets/orders/pair-sort.pipe';
import { TypeSortPipe } from './pipes/markets/orders/type-sort.pipe';
import { AmountSortPipe } from './pipes/markets/orders/amount-sort.pipe';
import { PriceSortPipe } from './pipes/markets/orders/price-sort.pipe';
import { DateSortPipe } from './pipes/markets/orders/date-sort.pipe';
import { TotalSortPipe } from './pipes/markets/orders/total-sort.pipe';
import { WalletDepositComponent } from './dialogs/wallet-deposit/wallet-deposit.component';
import { AddWalletComponent } from './dialogs/add-wallet/add-wallet.component';
import { WalletCreatedComponent } from './dialogs/wallet-created/wallet-created.component';
import { NotVerifiedComponent } from './dialogs/not-verified/not-verified.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ValidationService } from './services/validation.service';
import { VolumeUSDSortPipe } from './pipes/search/volume-usd-sort.pipe';
import { PairMarketNameSortPipe } from './pipes/search/pair-marketname-sort.pipe';
import { ConvertExpToNumberPipe } from './pipes/convert-exp-to-number.pipe';
import { CurrencySortPipe } from './pipes/search/currency-sort.pipe';
import { Change24hSortPipe } from './pipes/search/change24h-sort.pipe';
import { MouseWheelDirective } from './directive/mouse-wheel/mouse-wheel.directive';
// import { ImageLoaderComponent } from './pages/profile/identity-verification/image-loader/image-loader.component';
// import { GeneralInfoComponent } from './pages/profile/identity-verification/general-info/general-info.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppConfig } from './app.config';
import { KycPageComponent } from './pages/kyc-page/kyc-page.component';
import { KycModalComponent } from './dialogs/kyc-modal/kyc-modal.component';
import { AlertComponent } from './dialogs/alert/alert.component';
import { SetLandingLanguageComponent } from './pages/landing/set-landing-language/set-landing-language.component';
import { DayNightToggleComponent } from './partials/header/day-night-toggle/day-night-toggle.component';
import { CookieBarComponent } from './dialogs/cookie-bar/cookie-bar.component';
import { ThemeSwitcherComponent } from './partials/header/theme-switcher/theme-switcher.component';
import { ClickOutsideDirective } from './directive/click-outside.directive';
import { ShowCurrencyPipe } from './pipes/markets/show-currency.pipe';
import { OrderMarketsComponent } from './pages/markets/order-markets/order-markets.component';
import { TypeCurrencyPipe } from './pipes/markets/orders/type-currency.pipe';
import { BalanceSortPipe } from './pipes/markets/orders/balance-sort.pipe';
import { AddFiatComponent } from './dialogs/add-fiat/add-fiat.component';
import { TriggerPriceSortPipe } from './pipes/markets/orders/trigger-price-sort.pipe';
import { TitleService } from './services/title.service';
import { FeesAndLimitsComponent } from './pages/fees-and-limits/fees-and-limits.component';
import { FeesAndLimitsPipe } from './pipes/fees-and-limits/fees-and-limits.pipe';
import { FeesAndLimitsSearchPipe } from './pipes/fees-and-limits/fees-and-limits-search.pipe';
import { PublicApiComponent } from './pages/public-api/public-api.component';
import { PublicApi } from './models/public-api.model';
import { RedemptionHistoryPipe } from './pipes/bcxg/redemption-history.pipe';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BuyCryptoComponent} from './pages/buy-crypto/buy-crypto/buy-crypto.component';
import { BuyTDBonusesComponent } from './pages/buy-td-bonuses/buy-td-bonuses.component';
import {MatTabsModule} from '@angular/material/tabs';
import { WalletStatus } from './pages/wallet-status/wallet-status.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {MatInputModule} from '@angular/material/input';
import {CreditCardDirectivesModule} from 'angular-cc-library';
import { RedirectGuard } from './services/redirectGuard';
import { MainWrapperComponent } from './pages/main-wrapper/main-wrapper.component';
import { CustomInputComponent } from './ui/custom-input/custom-input.component';
import { ChipsSelectorComponent } from './ui/chips-selector/chips-selector.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {NgxMaskModule} from 'ngx-mask';
import { MinDirective } from './directive/min.directive';
import { MaxDirective } from './directive/max.directive';
import {MatButtonModule} from '@angular/material/button';
import { DepositComponent } from './pages/profile/deposit/deposit.component';
import { WithdrawComponent } from './pages/profile/withdraw/withdraw.component';
import { CustomSelectComponent } from './ui/custom-select/custom-select.component';
import { CheckBalanceDirective } from './directive/check-balance.directive';
import { HistoryComponent } from './pages/profile/history/history.component';
import {NgxCopyPasteModule} from 'ngx-copypaste';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { AlertDialogComponent } from './ui/alert-dialog/alert-dialog.component';
import { EbAppComponent } from './dialogs/eb-app/eb-app.component';
import { TwofaCheckComponent } from './dialogs/twofa-check/twofa-check.component';
import { MainNavComponent } from './partials/main-nav/main-nav.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import { InputDenySymbolsDirective } from './directive/input-deny-symbols.directive';
import {MobxAngularModule} from 'mobx-angular';
import { CarouselItemDirective } from './directive/carousel-item.directive';
import {CarouselComponent} from './ui/carousel/carousel.component';
import {MarketComponent} from './partials/market/market.component';
import {AboutUsComponent} from './pages/about-us/about-us.component';
import {FaqComponent} from './pages/faq/faq.component';
import {LandingService} from './services/landing.service';
import {ThemeService} from './services/theme.service';
import { ReceiveAuthTokenComponent } from './pages/receive-auth-token/receive-auth-token.component';
import { TechnodomComponent } from './pages/landing/technodom/technodom.component';
import {AltynBankComponent} from './pages/altyn-bank/altyn-bank.component';
import { WalletPipesModule } from './pipes/wallets/wallets-pipe.module';
import { SharedModule } from './shared/shared.module';
import {CryptoCredentialProgressStatusComponent} from './dialogs/crypto-credential-progress-status/crypto-credential-progress-status';
import { AuthHintComponent } from './dialogs/auth-hint/auth-hint.component';
import { AuthQrComponent } from './dialogs/auth-qr/auth-qr.component';
import { Toggle2FAResponseComponent } from './dialogs/toggle2-fa-response/toggle2-fa-response.component';
import {DomainService} from './services/domain.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: .4
};

export function initializeDomainService(service: DomainService): () => void {
  return () => service.handleDomainChange();
}

@NgModule({
  declarations: [
    AltynBankComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LandingComponent,
    // CryptoCredentialComponent,
    CryptoCredentialSendingComponent,
    TwoFactorAuthModalComponent,
    SearchComponent,
    MarketsComponent,
    WithdrawalComponent,
    WithdrawalSuccessComponent,
    SignUpComponent,
    // LogInComponent,
    CryptoCredentialSignUpComponent,
    CryptoCredentialPaymentStatusComponent,
    CryptoCredentialProgressStatusComponent,
    PaymentStatusComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    ShowCurrencyPipe,
    WalletWithdrawDepositPipe,
    WalletOrderTypePipe,
    SafeHtmlPipe,
    HomeSearchPipe,
    HomePlusPipe,
    MarketListComponent,
    MarketsSortPipe,
    AuthPageComponent,
    InfoComponent,
    BalancesComponent,
    OrdersComponent,
    CalculatorComponent,
    ChartComponent,
    TickersComponent,
    OrderHistoryComponent,
    OrderBookComponent,
    TradesComponent,
    ChartMarketDepthComponent,
    MarketDepthComponent,
    ChartContainerComponent,
    FilterKeysPipe,
    SearchFilterPipe,
    TokenNameSortPipe,
    LastSortPipe,
    VolumeSortPipe,
    Sort24hPipe,
    OrderHistoryFilterPipe,
    PairSortPipe,
    TypeSortPipe,
    AmountSortPipe,
    PriceSortPipe,
    TriggerPriceSortPipe,
    DateSortPipe,
    TotalSortPipe,
    WalletDepositComponent,
    AddWalletComponent,
    WalletCreatedComponent,
    NotVerifiedComponent,
    VolumeUSDSortPipe,
    PairMarketNameSortPipe,
    ConvertExpToNumberPipe,
    CurrencySortPipe,
    Change24hSortPipe,
    MouseWheelDirective,
    // ImageLoaderComponent,
    // GeneralInfoComponent,
    KycPageComponent,
    KycModalComponent,
    AlertComponent,
    SetLandingLanguageComponent,
    DayNightToggleComponent,
    CookieBarComponent,
    ThemeSwitcherComponent,
    ClickOutsideDirective,
    OrderMarketsComponent,
    TypeCurrencyPipe,
    BalanceSortPipe,
    AddFiatComponent,
    FeesAndLimitsComponent,
    FeesAndLimitsPipe,
    FeesAndLimitsSearchPipe,
    PublicApiComponent,
    RedemptionHistoryPipe,
    BuyCryptoComponent,
    BuyTDBonusesComponent,
    WalletStatus,
    MainWrapperComponent,
    CustomInputComponent,
    ChipsSelectorComponent,
    MinDirective,
    MaxDirective,
    DepositComponent,
    WithdrawComponent,
    CustomSelectComponent,
    CheckBalanceDirective,
    HistoryComponent,
    AlertDialogComponent,
    EbAppComponent,
    TwofaCheckComponent,
    MainNavComponent,
    InputDenySymbolsDirective,
    MarketComponent,
    CarouselItemDirective,
    CarouselComponent,
    AboutUsComponent,
    FaqComponent,
    ReceiveAuthTokenComponent,
    TechnodomComponent,
    AuthHintComponent,
    AuthQrComponent,
    Toggle2FAResponseComponent
  ],
  imports: [
    MatInputModule,
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    PerfectScrollbarModule,
    QRCodeModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    CreditCardDirectivesModule,
    RecaptchaModule, RecaptchaFormsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MobxAngularModule,
    NgxMaskModule.forRoot(),
    NgxCopyPasteModule,
    WalletPipesModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: AppConfig.httpLoaderFactory,
        deps: [HttpClient]
      }
    }), MatTabsModule, OverlayModule, MatIconModule, MatCheckboxModule, MatButtonModule, MatSidenavModule, MatToolbarModule, MatListModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    DataService,
    SvgService,
    ValidationService,
    TitleService,
    RedirectGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: AppConfig.init,
      deps: [TranslateService],
      multi: true
    },
    DomainService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeDomainService,
      deps: [DomainService],
      multi: true,
    },
    LandingService,
    ThemeService
  ],
  bootstrap: [ AppComponent ],
  entryComponents: [
    WithdrawalComponent,
    WithdrawalSuccessComponent,
    SignUpComponent,
    // LogInComponent,
    CryptoCredentialSignUpComponent,
    CryptoCredentialPaymentStatusComponent,
    CryptoCredentialProgressStatusComponent,
    PaymentStatusComponent,
    ForgotPasswordComponent,
    TwoFactorAuthModalComponent,
    NewPasswordComponent,
    InfoComponent,
    AddWalletComponent,
    WalletCreatedComponent,
    NotVerifiedComponent,
    WalletDepositComponent,
    KycModalComponent,
    AlertComponent,
    AddFiatComponent,
  ]
})
export class AppModule { }
