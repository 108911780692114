import {Component, ElementRef, Inject, OnDestroy, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import {PASSWORD_VALIDATION} from '../../app.constants';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnDestroy {
  @ViewChild('newPasswordHint') newPasswordHint: ElementRef;
  @ViewChild('oldPasswordHint') oldPasswordHint: ElementRef;
  public newPasswordForm: FormGroup;
  public lengthValid: boolean;
  public hasNumberValid: boolean;
  public hasUpperCaseValid: boolean;
  public hasSpecialSymbolsValid: boolean;
  public loader = false;
  public passwordNotCorrect = false;
  public isLightTheme = false;
  public displayPassword = false;
  public displayNewPassword = false;
  destroySubject$: Subject<void> = new Subject();
  constructor(private matDialogRef: MatDialogRef<NewPasswordComponent>,
              public dataService: DataService,
              @Inject(MAT_DIALOG_DATA) public token: string,
              @Inject(MAT_DIALOG_DATA) public data: string,
              private fb: FormBuilder,
              private authService: AuthService,
              private router: Router) {
    this.createForm();

    // if (window.localStorage.getItem('isLightTheme') === 'true') {
    //   this.isLightTheme = true;
    // } else {
    //   this.isLightTheme = this.dataService.getIsLightTheme();
    // }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }
  private createForm(): void {
    this.newPasswordForm = this.fb.group({
      // password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20), Validators.pattern(PASSWORD_VALIDATION)]],
      // passwordRepeat: ['', Validators.required]
      oldPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20), Validators.pattern(PASSWORD_VALIDATION)]],
      newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20), Validators.pattern(PASSWORD_VALIDATION)]],
    });
    //  {
    //   validator: this.passwordMatch
    // });
  }
  public changePassword(): void {
    this.passwordNotCorrect = false;
    this.loader = true;
    this.authService.changePassword({
      oldPassword: this.newPasswordForm.value.oldPassword,
      newPassword: this.newPasswordForm.value.newPassword,
    }).pipe(takeUntil(this.destroySubject$))
      .subscribe({
      next: (res) => {
        this.loader = false;
        this.matDialogRef.close(res);
      },
      error: (error) => {
        this.passwordNotCorrect = true;
        this.loader = false;
        this.newPasswordForm.reset();
      }
    });
  }
  private passwordMatch(group: FormGroup): void {
    const pw = group.controls['password'];
    const pwr = group.controls['passwordRepeat'];
    if (pw.value !== pwr.value) {
      pwr.setErrors({passwordMatch: true});
    } else {
      pwr.setErrors(null);
    }
  }
  onInputChange(control: string) {
    if (control === 'newPassword' && this.newPasswordHint) {
      this.newPasswordHint.nativeElement.style.display = 'flex';
    }
    this.passwordNotCorrect = false;
    this.lengthValid = this.newPasswordForm.controls[control].hasError('minlength');
    this.hasNumberValid = /\d/.test(this.newPasswordForm.controls[control].value);
    this.hasUpperCaseValid = /[A-Z]/.test(this.newPasswordForm.controls[control].value);
    this.hasSpecialSymbolsValid = /[!@#$%^&*()\-_+=;:,.\/?\\|`~\[\]{}<>]/.test(this.newPasswordForm.controls[control].value);
  }
  hideHints(el: ElementRef) {
    if (el) {
      el.nativeElement.style.display = 'none';
    }
  }
  close() {
    this.matDialogRef.close({
      old: this.password,
    });
  }
  get oldPassword() { return this.newPasswordForm.get('oldPassword'); }
  get newPassword() { return this.newPasswordForm.get('newPassword'); }
  get password() { return this.newPasswordForm.get('password'); }
  get passwordRepeat() { return this.newPasswordForm.get('passwordRepeat'); }
}
