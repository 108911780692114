import {Component, Inject, OnDestroy} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SvgService } from '../../services/svg.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnDestroy {
  public loginVerify: boolean;
  public passwordChangeVerify: boolean;
  public passwordChangeSuccess: boolean;
  public registrationSuccess: boolean;
  public validateEmailError: boolean;
  public authorizationProcessing: boolean;
  public paymentSuccess: boolean;
  public paymentFailure: boolean;
  public paymentCancellation: boolean;
  public paymentNotification: boolean;
  public paymentReceiptUnverified: boolean;
  public paymentTDBonusesSuccess: boolean;
  paymentTDBonusesNotVerified: boolean;
  public error: boolean;
  public message = '';
  public isLightTheme = false;
  destroySubject$: Subject<void> = new Subject();
  constructor(private dialog: MatDialogRef<InfoComponent>,
              public dataService: DataService,
              @Inject(MAT_DIALOG_DATA) private whatToDisplay,
              private router: Router,
              public svgService: SvgService) {
    switch (whatToDisplay.reason) {
      case 'login-verify': {
        this.loginVerify = true;
        break;
      }
      case 'password-change-verify': {
        this.passwordChangeVerify = true;
        break;
      }
      case 'password-change-success': {
        this.passwordChangeSuccess = true;
        break;
      }
      case 'registration-success': {
        this.registrationSuccess = true;
        break;
      }
      case 'validate-email-error': {
        this.validateEmailError = true;
        this.message = whatToDisplay.message || '';
        break;
      }
      case 'authorization-processing': {
        this.authorizationProcessing = true;
        // console.log(whatToDisplay);
        this.message = whatToDisplay.message || '';
        break;
      }
      case 'payment-success': {
        this.paymentSuccess = true;
        // console.log(whatToDisplay);
        this.message = whatToDisplay.message || '';
        break;
      }
      case 'payment-failure': {
        this.paymentFailure = true;
        // console.log(whatToDisplay);
        this.message = whatToDisplay.message || '';
        break;
      }
      case 'payment-cancellation': {
        this.paymentCancellation = true;
        // console.log(whatToDisplay);
        this.message = whatToDisplay.message || '';
        break;
      }
      case 'payment-notification': {
        this.paymentNotification = true;
        // console.log(whatToDisplay);
        this.message = whatToDisplay.message || '';
        break;
      }
      case 'payment-receipt-unverified': {
        this.paymentReceiptUnverified = true;
        console.log(whatToDisplay);
        this.message = whatToDisplay.message || '';
        break;
      }
      case 'td-bonuses-buy-success': {
        this.paymentTDBonusesSuccess = true;
        // console.log(whatToDisplay);
        this.message = whatToDisplay.message || '';
        break;
      }
      case 'td-bonuses-buy-not-verified': {
        this.paymentTDBonusesNotVerified = true;
        // console.log(whatToDisplay);
        this.message = whatToDisplay.message || '';
        break;
      }
      default: {
        this.error = true;
        break;
      }
    }
    this.dialog.afterClosed()
      .subscribe(() => { this.router.navigate(['/markets', {outlets: { popup: null }}]).then(); });

    // if (window.localStorage.getItem('isLightTheme') === 'true') {
    //   this.isLightTheme = true;
    // } else {
    //   this.isLightTheme = this.dataService.getIsLightTheme();
    // }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }
  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }
  public close() {
    this.dialog.close();
  }
}
